
















import Vue from 'vue'
import { Icon, Marker } from 'leaflet'
import { LIcon, LMarker } from 'vue2-leaflet'
import PlaceInformationBox from './PlaceInformationBox.vue'
import MapMarkerIcon from './MapMarkerIcon.vue'
import { QueryParameters } from '@/router'

// The following properties are set this way to avoid "net::ERR_INVALID_URL" errors in Chromium-like browsers and redundant files import as a default marker is not used anyway.
Icon.Default.prototype.options.imagePath = '.'
Icon.Default.prototype.options.iconUrl = ''
Icon.Default.prototype.options.shadowUrl = ''

export default Vue.extend({
  props: {
    place: {
      type: Object,
      required: true
    }
  },
  components: {
    LIcon,
    LMarker,
    MapMarkerIcon,
    PlaceInformationBox
  },
  data () {
    return {
      QueryParameters
    }
  },
  computed: {
    marker () : Marker {
      return (this.$refs.marker as LMarker).mapObject
    },
    popupShouldBeOpen () : boolean {
      return this.$route.query[QueryParameters.SelectedPlaceIdentifier] == this.place['id']
    }
  },
  mounted () {
    this.$nextTick().then(this.openOrClosePopupDependingOnQueryParameter)
  },
  watch: {
    popupShouldBeOpen () {
      this.openOrClosePopupDependingOnQueryParameter()
    }
  },
  methods: {
    openOrClosePopupDependingOnQueryParameter () {
      if(this.popupShouldBeOpen){
        this.marker.openPopup()
        var lastOpened : number = parseInt(localStorage.getItem('LAST_OPENED_' + this.place.id) || '');
        var now : number = Date.now();
        if(!lastOpened || lastOpened < (now - 3 * 60 * 1000)){
          const requestURL = new URL(`${location.origin}/en/watchout/api/place_statistics`)
          const params : any = {
            headers: {
              'Content-Type': "application/json"
            },
            method: "POST",
            body: JSON.stringify({
              "place": this.place.id,
              "type": "click",
              "event": this.$store.state.selectedEvent && this.$store.state.selectedEvent.id || null,
              "transmission_type": this.place.transmission_type || 0
            })
          };
          fetch(requestURL.toString(), params).then(response => response.json());
        }
        localStorage.setItem('LAST_OPENED_' + this.place.id, now.toString());
      } else {
        this.marker.closePopup()
      }
    }
  }
})
