













































import Vue from 'vue'
import InformationBox from './InformationBox.vue'


export default Vue.extend({
  components: {
    InformationBox
  }
})
