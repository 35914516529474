





































































































































import Vue from 'vue'
import { LPopup } from 'vue2-leaflet'

const ADDRESS_LINK_URL_BASIS = 'https://www.google.com/maps/search/?api=1'
const ADDRESS_LINK_SEARCH_PHRASE_PARAMETER_KEY = 'query'

export default Vue.extend({
  props: {
    place: {
      type: Object,
      required: true
    }
  },
  components: {
    LPopup
  },
  data () {
    return {
      navigator,
      copiedURLToClipboard: undefined as undefined | null | boolean,
      addressLinkURL: new URL(ADDRESS_LINK_URL_BASIS),
    }
  },
  computed: {
    addressLink () : string {
      this.addressLinkURL.searchParams.set(ADDRESS_LINK_SEARCH_PHRASE_PARAMETER_KEY, this.place['address'])
      return this.addressLinkURL.toString()
    },
    copyURLToClipboardButtonTooltipText () : string {
      return (this.copiedURLToClipboard ?  this.$t('copy_link_save_success')
        : this.copiedURLToClipboard === false ? this.$t('copy_link_save_failed')
          : this.$t('copy_link_message')) as string
    },
    likedPlaceButtonTooltipText () : string {
      return ((this.$store.state.likedPlaces.includes(this.place.id)) ?  this.$t('like_place_save_failed')
        : !(this.$store.state.likedPlaces.includes(this.place.id)) ? this.$t('like_place_save_success')
          : this.$t('like_place_message')) as string
    },
    leagueName() : string {
      const language = this.$store.state.currentLanguage;
      const event = this.$store.state.selectedEvent;
      if(!event.league){
        return event.league_name;
      }
      if (language){
        const nameKey = 'name_' + language;
        if(nameKey in event.league && event.league[nameKey]){
          return event.league[nameKey];
        }
      }
      return event.league['name'];
    },
    eventName() : string {
      const language = this.$store.state.currentLanguage;
      const event = this.$store.state.selectedEvent;
      if (language){
        const nameKey = 'name_' + language;
        if(nameKey in event){
          return event[nameKey];
        }
      }
      return event['name'];
    },
    isLikedPlace() : boolean {
      return this.$store.state.likedPlaces.includes(this.place.id);
    },
  },
  methods: {
    copyURLToClipboard () {
      navigator.clipboard.writeText(location.href)
        .then(() => this.copiedURLToClipboard = true)
        .catch(() => this.copiedURLToClipboard = false)
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
      return date;
    },
    statisticCount(type: string)
    {
        const requestURL = new URL(`${location.origin}/en/watchout/api/place_statistics`)
          const params : any = {
            headers: {
              'Content-Type': "application/json"
            },
            method: "POST",
            body: JSON.stringify({
              "place": this.place.id,
              "type": type,
              "event": this.$store.state.selectedEvent && this.$store.state.selectedEvent.id || null,
              "transmission_type": this.place.transmission_type || 0
            })
          };
          fetch(requestURL.toString(), params);
    }
  }
})
